import './Blog.css';
export default function Blog() {
    return (
        <div className="blog-page">
            <h1>Blog Posts</h1>
            <ul>
                <li><a href="/blog/hockley-review">Winter 2025 Hockley Valley Ski Resort Review</a></li>
            </ul>
        </div>
    )
}